import api from "@api";
import { nullGuid } from "@Ekcore/util/global.js";
import { getSearchResultOnRow } from "@Ekcore/util";

export default {
    state: {
        notifications: [],
        notificationDto: {
            title: "",
            body: "",
            sendToPackagesIds: [],
            sendToIds: [],
            facultyIds:[]
        },
        searchDto: {
            keys: [],
            query: "",
        },
    },
    getters: {
        notificationsList({ notifications }, getter , { filter } ) { 
            return  notifications.filter(el => {
                return getSearchResultOnRow(filter, el) 
            });
        },
    },
    mutations: {
        Get_Notification_List(state, payload) {
            state.notifications = payload 
        },
        Add_Notifications_SellPoint(state, payload) {
            state.notifications.unshift(payload);
        },
        Add_Notifications_Student(state, payload) {
            state.notifications.unshift(payload);
        },
        Delete_Notifications(state, ids) {
            state.notifications = state.notifications.filter(
                (el) => !ids.includes(el.id)
            ); 
        },
        Reset_Notifications_Dto(state) {
            Object.assign(state.notifications, {
                id: nullGuid,
                title: "",
                body: "",
                sendToIds: [],
                faculties:[]
            });
        },
    },
    actions: {
        getNotificationsList({ commit }) {
            api.get("Notification/Dash/GetAll", ({ data }) => {
                commit("Get_Notification_List", data);
            });
        },
        addNotifications({ commit }, payload) {
            api.post(
                "Notification/Dash/AddSellPoint",
                payload,
                ({ data }) => {
                    commit("Add_Notifications_SellPoint", data);
                },
                {
                    success: "تم إضافة الإشعار بنجاح",
                    error: "فشل إضافة الإشعار",
                }
            );
        },
        addStudentNotifications({ commit }, payload) {
            api.post(
                "Notification/Dash/AddStudent",
                payload,
                ({ data }) => {
                    commit("Add_Notifications_Student", data);
                },
                {
                    success: "تم إضافة الإشعار بنجاح",
                    error: "فشل إضافة الإشعار",
                }
            );
        },
        deleteNotificationsRang({ commit }, ids) {
            api.delete(
                "Notification/Dash/Delete",
                () => {
                    commit("Delete_Notifications", ids);
                },
                {
                    confirm: " ?هل تريد بالتأكيد حذف الاشعارات المحددة",
                    success: "تم حذف الاشعارات بنجاح",
                    error: "فشل الحذف ..يرجى اعادة المحاولة ",
                },
                ids
            );
        },
    },
};
